import React, { Suspense, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import Loading from './components/basic/Loading';
import AuthGuard from './components/AuthGuard';
import Dashboard from './components/layout/dashboard';
import store from './store'
import './assets/styles/global.css'
const rtlLocales = ['ar'];

function App() {
  const { i18n } = useTranslation();

  function onLangChangeHandler(lang) {
    const html = document.getElementsByTagName('html')[0];
    const splitLang = lang.split('-');

    if (splitLang[0] === 'en') {
      html.classList.remove('rtl');
    } else {
      html.classList.add('rtl');
    }
  }

  useEffect(() => {
    onLangChangeHandler(i18n.language);
  });
  return (
    <Suspense fallback={<Loading />}>
      <div className="main-wrapper" lang={i18n.language}>
        <ConfigProvider
          direction={rtlLocales.indexOf(i18n.language) > -1 ? 'rtl' : 'ltr'}
        >
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        </ConfigProvider>
      </div>
    </Suspense>
  );
}

export default App;
