import getAgentInstance from './superagent'
const baseUrl = process.env.REACT_APP_API_URL
export async function getRequest(url){
    const superagent = getAgentInstance()
    let data = await superagent.get(baseUrl+url)
    return data.body
}

export async function postRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.post(baseUrl+url).send(body)
    return data.body
}

export async function putRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.put(baseUrl+url).send(body)
    return data.body
}

export async function patchRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.patch(baseUrl+url).send(body)
    return data.body
}

export async function deleteRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.delete(baseUrl+url).send(body)
    return data.body
}
