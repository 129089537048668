/* eslint-disable max-classes-per-file */
import {computed, observable,action} from 'mobx';
import { persist, create } from 'mobx-persist';

class Token {

  @persist @observable value = '';

  @observable clear() {
    this.value = '';
  }

  @action
  setToken(token){
    this.value=token
    localStorage.setItem(process.env.REACT_APP_TOKEN_LOCALSTORAGE_NAME,JSON.stringify(this))
  }
}
class User {
  @persist('object') @observable value = {};

  @observable clear() {
    this.value = {};
  }

  @action
  setUser(user){
    this.value=user
    localStorage.setItem(process.env.REACT_APP_USER_LOCALSTORAGE_NAME,JSON.stringify(this))
  }
}

// const store = (window.store = new Store());

const userStore = new User();
const tokenStore = new Token();
const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

hydrate(process.env.REACT_APP_USER_LOCALSTORAGE_NAME, userStore).then(() => {});
hydrate(process.env.REACT_APP_TOKEN_LOCALSTORAGE_NAME, tokenStore).then(() => {});

export default {
  userStore,
  tokenStore,
};
