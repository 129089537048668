import {lazy} from 'react';
import i18n from '../../i18n';

import {
    SendOutlined,
    FontColorsOutlined
} from '@ant-design/icons'

const options = [
    {
        key: Math.random(),
        path: '/',
        component: lazy(() => import('../whatsapp/WhatsappSender')),
        exact: true,
        name: i18n.t('Sender'),
        icon: SendOutlined,
        render: true
    },
];
export default options;
